import Header from "@components/layout/header";
import Image from "next/image";
import ErrorImage from "@public/404.png";
import Link from "next/link";
import Footer from "@components/layout/footer";
import { Trans } from "@lingui/macro";
import { GetStaticProps } from "next";
import { loadTranslation } from "@lib/utils";

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const translation = await loadTranslation(
    locale,
    process.env.NODE_ENV == "production"
  );

  return { props: { translation } };
};

const ErrorPage: React.FunctionComponent = () => {
  return (
    <>
      <Header />
      <div className="align-center mx-auto my-24 flex max-w-7xl flex-col px-4 lg:flex-row lg:space-x-12 ">
        <div className="flex max-w-4xl justify-around px-16 lg:px-4">
          <Image src={ErrorImage} alt="Error Page" className="flex-1" />
        </div>
        <div className="flex flex-col justify-center space-y-4 text-center lg:text-left">
          <p className="font-poppins text-3xl font-bold text-text-dark">
            <Trans>'Breaking' news...</Trans>
          </p>
          <p className="font-poppins text-text-medium">
            <Trans>Sorry, the page you're looking for doesn't exist.</Trans>
            &nbsp;
            <Link href="/">
              <a className="cursor-pointer text-primary underline">
                <Trans>Go back home</Trans>
              </a>
            </Link>
            &nbsp;
            <Trans>or search for something else.</Trans>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ErrorPage;
